export default [
  // {
  //   title: 'Minha Conta',
  //   icon: 'address-card',
  //   route: 'account-seller',
  //   rule: []
  // },
  {
    title: 'Ofertas',
    icon: 'fa-car',
    route: 'shopping-offers-list',
    rule: []
  },
  {
    title: 'Repasses',
    icon: 'fa-retweet',
    href: '/ofertas?repass=true',
    rule: []
  },
  {
    title: 'Leilão',
    icon: 'fa-car-on',
    href: '/ofertas?auction=true',
    rule: []
  },
  {
    title: 'Consultar Fipe (Placa)',
    icon: 'money-bill-trend-up',
    route: 'shopping-consulting-fipe-plate',
    rule: ['p.admin.company.view']
  },
  {
    title: 'Sair',
    icon: 'power-off',
    route: 'login',
    rule: []
  }
]
