export default [
  {
    path: '/',
    name: 'home',
    public: true,
    component: () => import('../pages/Index.vue')
  },
  {
    path: '/login',
    name: 'login',
    public: true,
    component: () => import('../pages/Login/Index.vue')
  },
  {
    path: '/esqueci-minha-senha',
    name: 'recover-password',
    public: true,
    component: () => import('../pages/Login/recover-password.vue')
  },
  {
    path: '/me-cadastrar',
    name: 'register',
    public: true,
    component: () => import('../pages/Register/Index.vue')
  }
]
