export default [
  {
    path: '/lojista/estoque',
    name: 'company-stock-index',
    meta: { layout: 'default' },
    component: () => import('../pages/stock/Index.vue')
  },
  {
    path: '/lojista/estoque/oferta/:id',
    name: 'company-offer-create',
    meta: { layout: 'default' },
    component: () => import('../pages/offers/Create.vue')
  },
  {
    path: '/lojista/minha-conta',
    name: 'account-store',
    meta: { layout: 'default' },
    component: () => import('../pages/account/index.vue')
  },
  {
    path: '/lojista/pagamento',
    name: 'payment-index',
    meta: { layout: 'default' },
    component: () => import('../pages/payment/index.vue')
  },
  {
    path: '/lojista/assinaturas',
    name: 'subscriptions-index',
    meta: { layout: 'default' },
    component: () => import('../pages/subscriptions/index.vue')
  },
  {
    path: '/lojista/leilão',
    name: 'auction-index',
    meta: { layout: 'default' },
    component: () => import('../pages/auction/index.vue')
  },
  {
    path: '/checkout',
    name: 'checkout-my-plan',
    meta: { layout: 'default' },
    component: () => import('../../shared/payment/signature-plan.vue')
  }
]
