export default [
  {
    path: '/vendedor/minha-conta',
    name: 'account-seller',
    meta: { layout: 'default' },
    component: () => import('../pages/account/index.vue')
  }

  //   {
  //     path: '/estoque',
  //     name: 'company-stock-index',
  //     meta: { layout: 'default' },
  //     component: () => import('../pages/stock/Index.vue')
  //   },
  //   {
  //     path: '/estoque/oferta/:id',
  //     name: 'company-offer-create',
  //     meta: { layout: 'default' },
  //     component: () => import('../pages/offers/Create.vue')
  //   },
  // ,
  //   {
  //     path: '/meu-plano',
  //     name: 'account-my-plan',
  //     meta: { layout: 'default' },
  //     component: () => import('../pages/account/my-plan.vue')
  //   }
]
